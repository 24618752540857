import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; 
import "../Style/Services.css";
import { useLanguage } from '../LanguageContext';

function Services() {
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [guestCount, setGuestCount] = useState("");
  const { language } = useLanguage();
  const [services, setServices] = useState({
    qonaqlama: false,
    metbex: false,
    turlar: false,
    kendteserrufatimehhsullari: false,
  });
  const [result, setResult] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const navigate = useNavigate(); 
  const searchLocation = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(searchLocation.search);
    const searchTerm = query.get("search");
  
    fetch('https://ecorest.az/backend/get_best_places.php')
      .then(response => response.json())
      .then(data => {
        console.log("Fetched Data:", data); // Gelen veriyi kontrol etmek için
        if (searchTerm) {
          const filteredData = data.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setServiceItems(filteredData);
          console.log("Filtered Data:", filteredData); // Filtrelenen veriyi kontrol edin
        } else {
          setServiceItems(data);
        }
      })
      .catch(error => console.error(language === 'az' ? "Verilər yüklənmədi:" : "Failed to load data:", error));
  }, [searchLocation.search, language]);
  

  const handleSubmit = (event) => {
    event.preventDefault();
    const servicesSelected = Object.keys(services)
      .filter((service) => services[service])
      .join(", ");

    const resultText = `${location}, ${language === 'az' ? 'Başlama tarixi' : 'Start Date'}: ${startDate}, ${guestCount} ${language === 'az' ? 'qonaq sayı' : 'guest count'}, ${servicesSelected}`;
    setResult(resultText);
  };

  const handleDetailClick = (id, serviceType) => {
    if (serviceType === "hostels") {
        navigate(`/services/hostels/${id}`);
    } else if (serviceType === "kitchen") {
        navigate(`/services/kitchen/${id}`);
    } else if (serviceType === "organicpro") {
        navigate(`/services/organicpro/${id}`);
    } else if (serviceType === "events") {
        navigate(`/services/events/${id}`); // Tek '/' kullanımı
    } else {
        console.error(language === 'az' ? "Bilinməyən xidmət növü:" : "Unknown service type:", serviceType);
    }
  
};


  return (
    <div className="services-page">
      <div className="left-filter">
        <div className="left-search">
          <h2>{language === 'az' ? 'Axtarış' : 'Search'}</h2>
          <form onSubmit={handleSubmit}>
           <label>{language === 'az' ? 'Məkan' : 'Location'}</label>
            <select
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            > <option></option>
            </select>
            <label>{language === 'az' ? 'Başlama tarixi' : 'Start Date'}</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          
            <label>{language === 'az' ? 'Qonaq sayı' : 'Guest Count'}</label>
            <input
              type="number"
              min={1}
              value={guestCount}
              onChange={(e) => setGuestCount(e.target.value)}
            />
            <button type="submit">{language === 'az' ? 'Axtar' : 'Search'}</button>
          </form>
        </div>
        <div className="left-input">
          <h2>{language === 'az' ? 'Xidmətlər' : 'Services'}</h2>
          <div>
            <input
              type="checkbox"
              checked={services.qonaqlama}
              onChange={() =>
                setServices({
                  ...services,
                  qonaqlama: !services.qonaqlama,
                })
              }
            />
            <label>{language === 'az' ? 'Qonaqlama' : 'Accommodation'}</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={services.metbex}
              onChange={() =>
                setServices({ ...services, metbex: !services.metbex })
              }
            />
            <label>{language === 'az' ? 'Mətbəx' : 'Kitchen'}</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={services.turlar}
              onChange={() =>
                setServices({ ...services, turlar: !services.turlar })
              }
            />
           <label>{language === 'az' ? 'Turlar' : 'Tours'}</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={services.kendteserrufatimehhsullari}
              onChange={() =>
                setServices({
                  ...services,
                  kendteserrufatimehhsullari: !services.kendteserrufatimehhsullari,
                })
              }
            />
            <label>{language === 'az' ? 'Kənd Təsərrüfatı Məhsulları' : 'Agricultural Products'}</label>
          </div>
        </div>
      </div>
      <div className="right-services">
        <div className="search-info">
          <h2>{result}</h2>
        </div>

        {serviceItems.length > 0 ? (
          
          serviceItems.map((item, index) => (
            
            <div className="service-option" key={index}>
              <div className="service-img">
                <img src={`https://ecorest.az/backend/${item.image_url}`} alt={item.name} />
              </div>
              <div className="service-info">
                <h2>{item.name}</h2>
                <p>{item.location}</p>
                <div className="service-price">
                  <h3>{item.price ? `${item.price} AZN` : (language === 'az' ? "Qiymət yoxdur" : "No price available")}</h3>
                </div>
                <div className="service-detail">
                  <button 
                    type="button" 
                    onClick={() => handleDetailClick(item.id, item.service_type)}> 
                    {language === 'az' ? 'Ətraflı' : 'Details'}
                  </button>
                </div>
            
              </div>
            </div>
          ))
        ) : (
          <p>{language === 'az' ? 'Heç bir nəticə tapılmadı.' : 'No results found.'}</p>
        )}
      </div>
    </div>
  );
}

export default Services;
